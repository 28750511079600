import { useQuery } from '@apollo/client';
import { PlatformResponse } from '@/Lib/types/platform-response';
import { PLATFORM_QUERY } from '@/Lib/graphql/queries/platform.query';
import { GImage } from '@/Components/g-image';
import { GStarRating } from '@/Components/g-star-rating';
import styles from './vendor-review.module.css';
import { useState } from 'react';

const VendorReview = () => {
  const { data } = useQuery<PlatformResponse>(PLATFORM_QUERY);

  let reviewVendors = [
    {
      name: 'Consumer Affairs',
      rating: data?.config.reviews.consumerAffairs
        ? Math.round(data?.config.reviews.consumerAffairs.rating * 10) / 10
        : 0,
      reviews: [
        {
          name: 'Gabrielle R',
          division: 'Au Pair',
          rating: 4,
          description: `I wasn't sure at first about going with such a big company. I didn't want to get lost in the system with the chunders of other applicants; however I took that chance because I read so many great reviews and started my application process. I emailed inquiring about some programs and within the next few days I was contacted straight away and had any questions answered by the salesperson (he was very helpful and honest with all the questions I had asked etc). After I was in the next stage I was passed on the representative if you will who has been so so very helpful throughout the application, matching, pre-departure processes etc. I am very please with the prompt and honest responses I have received, and I would highly recommend this agency as they have been so helpful.`,
        },
      ],
      reviewCount: data?.config.reviews.consumerAffairs
        ? data?.config.reviews.consumerAffairs.count
        : 0,
      link: `</br><a href="https://www.consumeraffairs.com/travel/the-global-work-travel-co.html" target="_blank">Consumer Affairs</a>`,
      logo: 'website/static/reviews/consumer-affairs.svg',
    },
    {
      name: 'Trustpilot',
      rating: data?.config.reviews.trustpilot
        ? Math.round(data?.config.reviews.trustpilot.rating * 10) / 10
        : 0,
      reviews: [
        {
          name: 'Hayley L',
          division: 'Working Holiday',
          rating: 5,
          description: `In the beginning of calling Global Work & Travel Co. I was nervous! But after booking my deposit, I realized I was more excited than nervous! This company is great and they answer all the questions I had and provided great feedback on what’s provided and what’s not.`,
        },
        {
          name: 'Samantha M',
          division: 'Working Holiday',
          rating: 5,
          description: `This company helps you do everything and makes you are 100% safe throughout the whole trip. They are very well at answering any questions you may ask.`,
        },
        {
          name: 'Kizzy H',
          division: 'Working Holiday',
          rating: 3,
          description: `The lady I spoke to on the phone was very friendly, positive and helpful, thanks:)`,
        },
      ],
      reviewCount: data?.config.reviews.trustpilot
        ? data?.config.reviews.trustpilot.count
        : 0,
      link: `</br><a href="https://au.trustpilot.com/review/globalworkandtravel.com" target="_blank">Trustpilot</a>`,
      logo: 'website/static/reviews/trustpilot.svg',
    },
    {
      name: 'Reviews.io',
      rating: data?.config.reviews.reviewsIO
        ? Math.round(data?.config.reviews.reviewsIO.rating * 10) / 10
        : 0,
      reviews: [
        {
          name: 'Jake D',
          division: 'Teach Overseas',
          rating: 5,
          description: `Jemma was fantastic to deal with. I could not have asked for better support throughout this planning period. I looked into your company because I was looking for comfort and reassurance and Jemma's support and knowledge of the program made this whole experience less frustrating and very pleasant.`,
        },
        {
          name: 'Calloway K',
          division: 'Working Holiday',
          rating: 5,
          description: `I've never spoken to a more friendly or upbeat person in my life! Carly was so amazing with helping me plan this incredible trip!`,
        },
        {
          name: 'Anonymous',
          division: 'Working Holiday',
          rating: 4,
          description: `Explained everything clearly. Made sure I was comfortable with each step of the process`,
        },
      ],
      reviewCount: data?.config.reviews.reviewsIO
        ? data?.config.reviews.reviewsIO.count
        : 0,
      link: `</br><a href="https://www.reviews.io/company-reviews/store/the-global-work-travel-co" target="_blank">Reviews.io</a>`,
      logo: 'website/static/reviews/reviews.svg',
    },
    {
      name: 'Google',
      rating: data?.config.reviews.google
        ? Math.round(data?.config.reviews.google.rating * 10) / 10
        : 0,
      reviews: [
        {
          name: 'Sam B',
          division: 'Working Holiday',
          rating: 5,
          description: `Would highly recommend Global Work & Travel if you're looking to do a working holiday. I originally tried to organise my Canada Visa myself and was struggling until I found these guys. They helped me out with every process along the way and it were super easy to contact. Being assigned to an agent the whole time made a really personal experience too. Keep in mind to be flexible with your travel plans though as they cannot achieve the impossible and find your dream job in an the perfect town. Hannah Henderson was my trip coordinator in Vancouver and made everything easy with prompt emails and answering all my questions. As long as you do your side of the paperwork and send them the info they need you definitely won't be disappointed. Thanks alot Global and cheers Hannah, Dylan and Mark for the help 5 stars!!!`,
        },
      ],
      reviewCount: data?.config.reviews.google
        ? data?.config.reviews.google.count
        : 0,
      link: `Google</br><a href="https://www.google.com.au/search?q=the+global+work+and+travel+co&oq=the+global+work+and+travel+co&aqs=chrome.0.0j69i60l3j0l2.3990j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6b910f8d31a8488d:0x68a12fb923225ee4,1,,,," target="_blank">AU & NZ</a>,
                      <a href="https://www.google.com.au/search?q=The+Global+Work+%26+Travel+Co.+UK+12+Great+Turnstile+London&ludocid=6908778923738674773&hl=en-GB&gws_rd=cr&dcr=0&ei=FSUeWtynNNHo0gTfxYvwCw#lrd=0x48761ad5370cca25:0x5fe0e9d3ad263655,1" target="_blank">UK & EU</a> and
                      <a href="https://www.google.com.au/search?q=The+Global+Work+%26+Travel+Co.+Canada+Level+6,+1525+Robson+St+Vancouver&ludocid=12343038482773168418&hl=en-GB&gws_rd=cr&dcr=0&ei=JSUeWqS5MYaT8wWs7JzYCA#lrd=0x5486718797dd3bef:0xab4b481a96abd122,1" target="_blank">CA & USA</a>`,
      logo: 'website/static/reviews/google.svg',
    },
  ];
  return (
    <div className="mx-5 sm:mx-8 xl:mx-24 mt-16 mb-12">
      <h2 className="text-4xl leading-9 mb-2.5 text-dark-800 font-semibold">
        Global Travellers love our better trips!
      </h2>
      <p className="text-lg leading-6 mb-1.25 text-dark-700">
        They’ve given us an average rating of {data?.config.reviews.rating} from
        over {data?.config.reviews.count.toLocaleString()} real & verified
        reviews!
      </p>
      <GStarRating rating={data?.config.reviews.rating || 0} size={1} />
      <div className="grid gap-8 mt-8 grid-cols-rep-1-mx sm:grid-cols-rep-2-mx xl:grid-cols-about-grid">
        {reviewVendors.map((item, index) => (
          <div key={index}>
            <div className="xl:max-w-16rem">
              <div className="inline-block max-w-15.62rem">
                <GImage
                  path={item.logo}
                  styles={{ objectFit: 'contain' }}
                  alt={`${item.logo} logo`}
                />
              </div>
              <hr className="mt-4 mb-8 border-light-900" />
              {item.reviews.map((reviewItem, reviewIndex) => (
                <div className="mb-8" key={reviewIndex}>
                  <h4 className="text-base leading-5 mb-1.25 font-semibold text-dark-800">
                    {reviewItem.name}, {reviewItem.division}
                  </h4>
                  <GStarRating rating={reviewItem.rating} size={1} />
                  <p className="text-base leading-5 mt-2.5 text-dark-700">
                    {reviewItem.description}
                  </p>
                </div>
              ))}
              <p className="text-base leading-5 text-dark-700">
                See all{' '}
                <span className="font-semibold">
                  {item.reviewCount?.toLocaleString()}
                </span>{' '}
                reviews on
                <span
                  className={styles.vendor}
                  dangerouslySetInnerHTML={{
                    __html: item.link,
                  }}
                ></span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default VendorReview;
