import { ModalCentered } from '@/Components/modals/modal-centered';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useState } from 'react';

type Props = {
  id: string;
  title: string;
};

const YoutubeCardMobile = ({ id, title }: Props) => {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<any>(null);
  const { isMD } = useDevice();
  const openVideoModal = () => setIsVideoOpen(true);

  return (
    <>
      <div className="relative ">
        <div
          className="absolute w-full h-12.5 top-0 "
          onClick={openVideoModal}
        ></div>
        <iframe
          src={`https://www.youtube.com/embed/${id}?controls=0&playsinline=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder={0}
          className="w-full h-12.5 overflow-hidden rounded-md"
        ></iframe>
        <h3 className="text-xl mt-2.5 mb-1.25 leading-6 font-semibold text-dark-800">
          {title}
        </h3>
      </div>
      <ModalCentered
        isModalOpen={isVideoOpen}
        setIsModalOpen={setIsVideoOpen}
        noPadding
        noCloseButton
      >
        <iframe
          src={`https://www.youtube.com/embed/${id}?controls=0&playsinline=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder={0}
          width={isMD ? '840' : '320'}
          height={isMD ? '472' : '180'}
        ></iframe>
      </ModalCentered>
    </>
  );
};
export default YoutubeCardMobile;
