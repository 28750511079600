import { Gallery } from '@/Components/gallery';
import { galleryData } from '@/Lib/types/gallery';

type Props = {
  galleryData: galleryData;
};
const GalleryReview = ({ galleryData }: Props) => {
  return (
    <div className="mx-5 sm:mx-8 xl:mx-24 my-12">
      <h2 className="text-4xl leading-9 mb-2.5 text-dark-800 font-semibold">
        A picture is worth a thousand words
      </h2>
      <p className="text-lg leading-6 text-dark-700">
        Can you blame our Global Travellers for being snap-happy in beautiful
        destinations like this?! Catch a glimpse of the magnificent trips and
        adventures our Global Travellers are on. With tens of thousands of
        followers, there’s plenty of action on our Instagram. Join the fun and
        share your snaps via{' '}
        <a
          className="text-teal-900"
          href="https://www.instagram.com/explore/tags/globalworkandtravel/"
          target="_blank"
          rel="noreferrer"
        >
          #globalworkandtravel.
        </a>
      </p>
      <div className="mt-8">
        <Gallery
          tags={['Generic']}
          min={14}
          hasPadding={false}
          isInReviewPage={true}
          data={galleryData}
        />
      </div>
    </div>
  );
};
export default GalleryReview;
