import { Children, FC, useEffect, useReducer, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

type SwipeableProps = {
  children: JSX.Element[];
  cardWidth: number;
  spaceBetween: number;
  resetSeekOn?: string;
  hasLeftRightButtons?: boolean;
  hasAlternativeLeftRightButtons?: boolean;
  Interval?: boolean;
  innerPadding?: number;
  setsHeight?: boolean;
  hasLeftRightCircleButtons?: boolean;
  hasCardParentPadding?: boolean;
};

const Swipeable = ({
  children,
  cardWidth,
  spaceBetween,
  resetSeekOn,
  hasLeftRightButtons = false,
  hasAlternativeLeftRightButtons = false,
  Interval = false,
  innerPadding = 0,
  setsHeight = false,
  hasLeftRightCircleButtons,
  hasCardParentPadding = false,
}: SwipeableProps) => {
  const [seek, setSeek] = useState<number>(0);
  const [parentWidthInRem, setParentWidthInRem] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  const cardCount = Children.count(children);
  const calculatedWidth = cardWidth + spaceBetween;

  useEffect(() => {
    setSeek(0);
  }, [resetSeekOn]);

  useEffect(() => {
    if (setsHeight) {
      const currentCardIndex = Math.abs(Math.round(seek / calculatedWidth));
      const f: NodeListOf<HTMLDivElement> | undefined =
        ref?.current?.querySelectorAll('.swipeable-card>div>div');
      setContainerHeight((f && f[currentCardIndex]?.offsetHeight) || 0);
    }
  }, [seek]);

  useEffect(() => {
    if (ref.current) {
      setParentWidthInRem(ref.current.offsetWidth / 16);
    }
  }, [ref.current]);

  const fractionOfCardsOutsideParent =
    cardCount * calculatedWidth + (innerPadding || 0) - parentWidthInRem;

  const slideTo = (direction: string) => {
    if (direction === 'right') {
      if (seek + calculatedWidth <= 0) {
        setSeek(seek + calculatedWidth);
      } else if (seek + calculatedWidth > 0 && seek < 0) {
        setSeek(0);
      }
    } else if (direction === 'left') {
      if (fractionOfCardsOutsideParent < 0) {
        return;
      }
      if (Math.abs(seek - calculatedWidth) < fractionOfCardsOutsideParent) {
        setSeek(seek - calculatedWidth);
      } else {
        setSeek(-fractionOfCardsOutsideParent);
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slideTo('left'),
    onSwipedRight: () => slideTo('right'),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      Interval && slideTo('left');
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seek]);

  return (
    <>
      <div
        {...handlers}
        className={
          'relative flex items-center' +
          (hasLeftRightCircleButtons && ' md:flex md:items-center ')
        }
      >
        {hasLeftRightCircleButtons ? (
          <div className="hidden w-3.75rem md:flex items-center justify-start ">
            <div
              className={
                ' cursor-pointer h-10 w-10 rounded-full bg-light-600 flex justify-center items-center ' +
                (seek >= 0 ? ' bg-light-800 ' : 'border border-dark-500')
              }
              onClick={() => slideTo('right')}
            >
              <i
                className={'icon-left text-sm text-dark-700 font-semibold'}
              ></i>
            </div>
          </div>
        ) : null}
        <div
          className={
            'relative w-full  overflow-hidden' +
            (innerPadding ? ' pl-5' : '') +
            (hasCardParentPadding ? ' pb-2' : '')
          }
          style={
            setsHeight
              ? {
                  maxHeight: `${containerHeight}px`,
                  transition: 'max-height 0.3s ease-in-out',
                }
              : undefined
          }
          ref={ref}
        >
          <div
            className="flex swipeable-card"
            style={{
              transition: 'transform 0.3s ease',
              transform: `translateX(${seek}rem)`,
            }}
          >
            {Children.map(children, (child, index) => (
              <div
                key={index}
                style={{
                  flexShrink: 0,
                  flexBasis: cardWidth + 'rem',
                  marginRight: `${spaceBetween}rem`,
                }}
              >
                {child}
              </div>
            ))}
          </div>
          {hasLeftRightButtons ? (
            <div>
              <div
                className="absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() => slideTo('right')}
              >
                <i
                  className={
                    'icon-left text-6xl text-light-600' +
                    (seek >= 0 ? ' opacity-40' : '')
                  }
                ></i>
              </div>
              <div
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() => slideTo('left')}
              >
                <i
                  className={
                    'icon-right text-6xl text-light-600' +
                    (Math.abs(seek - calculatedWidth) ===
                    // @ts-ignore
                    cardCount * calculatedWidth
                      ? ' opacity-40'
                      : '')
                  }
                ></i>
              </div>
            </div>
          ) : null}
        </div>
        {hasLeftRightCircleButtons && (
          <div className="hidden w-3.75rem md:flex items-center justify-end ">
            <div
              className={
                ' cursor-pointer h-10 w-10 rounded-full bg-light-600 flex justify-center items-center ' +
                (Math.abs(seek) >= fractionOfCardsOutsideParent
                  ? ' bg-light-800 '
                  : ' border border-dark-500')
              }
              onClick={() => slideTo('left')}
            >
              <i
                className={'icon-right text-sm text-dark-700 font-semibold '}
              ></i>
            </div>
          </div>
        )}
        {hasAlternativeLeftRightButtons ? (
          <div>
            <div
              className="absolute left-2 xl:-left-10 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => slideTo('right')}
            >
              <i
                className={
                  'icon-left text-3xl text-dark-600' +
                  (seek >= 0 ? ' opacity-40' : '')
                }
              ></i>
            </div>
            <div
              className="absolute right-2 xl:-right-10 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => slideTo('left')}
            >
              <i
                className={
                  'icon-right text-3xl text-dark-600' +
                  (Math.abs(seek) >= fractionOfCardsOutsideParent
                    ? ' opacity-40'
                    : '')
                }
              ></i>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export { Swipeable };
