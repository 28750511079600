import { GImage } from '@/Components/g-image';
import { ConfigQuery } from '@/Lib/graphql/queries/config.query';
import { useQuery } from '@apollo/client';
import { useDevice } from '@/Lib/hooks/useDevice';
import { AppLink } from '../link/link';
const VendorList = () => {
  const { data } = useQuery<{ config: any }>(ConfigQuery);
  const { isMD } = useDevice();

  const highReviews = toCount(
    data ? data.config.reviews.ratingCount : 0
  )?.toLocaleString();
  const vendors: string[] = [
    'abta',
    'bc',
    'atia',
    'trustpilot',
    'google',
    'consumer-affairs',
    'reviews',
    'productreview',
    'glassdoor',
  ];

  const vendorImageNames: { [key: string]: string } = {
    google: 'google-logo.png',
    trustpilot: 'trustpilot-logo.png',
    'consumer-affairs': 'consumer-affairs-logo.png',
    reviews: 'reviews-logo.png',
    productreview: 'productreview-logo.png',
    glassdoor: 'glassdoor-logo.png',
    atia: 'atia.jpg',
    bc: 'bc.jpg',
  };

  return (
    <div className="max-w-6.75xl m-auto">
      <div className="my-12 mx-5 sm:mx-8 xl:mx-24">
        <h2 className="text-4xl leading-9 text-dark-800 mb-2.5 font-semibold">
          Travel you can trust
        </h2>
        <p
          className="text-lg leading-6 mb-5 text-dark-700"
          suppressHydrationWarning
        >
          We’re members of the travel industry&apos;s most prominent & renowned
          accreditation groups and associations. With well over {highReviews} 4
          & 5 star{' '}
          <AppLink href="/reviews" className="text-teal-900 underline">
            reviews
          </AppLink>
          , make travel arrangements with a company you can trust to deliver.
        </p>
        <div className="mt-5 grid grid-cols-2-1fr grid-rows-5-90px justify-center sm:grid-cols-3-1fr sm:grid-rows-3-150px ">
          {vendors.map((item, index) => (
            <div key={index} className="w-auto h-full">
              <GImage
                path={`website/static/travel-you-can-trust/${
                  vendorImageNames[item] || item + '.svg'
                }`}
                alt={`${item} icon`}
                width="100%"
                height="100%"
                styles={{
                  objectFit: 'contain',
                  maxWidth: !isMD
                    ? item === 'atia'
                      ? '45%'
                      : '80%'
                    : item === 'atia'
                    ? '34%'
                    : '60%',
                  objectPosition: 'center',
                  margin: 'auto',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default VendorList;
const toCount = (count: number) => {
  const rounded = count - (count % 1000);
  return rounded;
};
