import YoutubeCard from '@/Components/cards/youtube-card';
import YoutubeCardMobile from '@/Components/cards/youtube-card/mobile';
import { Swipeable } from '@/Components/swipeable/swipeable';

const Youtube = () => {
  const youtubeArticles = [
    {
      title: 'Teach Overseas with Global',
      id: 'cOFzwj4q0SA',
    },
    {
      title: 'Working Holiday in Canada with Jaime',
      id: 'o0bWe3sr2ww',
    },
    {
      title: 'Elephant Rehabilitation Volunteering in Thailand with Ky',
      id: 'sB6wQ8blVl4',
    },
  ];
  return (
    <div className="mb-12 gap-x-8">
      <div className="max-w-6.75xl m-auto">
        <div className="mx-5 sm:mx-8 xl:mx-24">
          <h2 className="text-4xl leading-9 mb-2.5 text-dark-800 font-semibold">
            Lights, camera, action!
          </h2>
          <p className="text-lg leading-6 text-dark-700">
            If you need to see it to believe it, get the inside scoop as past
            Global Travellers talk about their trips and show you their
            behind-the-scenes footage from their own overseas adventures. Hear
            their recaps and get inspired when you see how much fun they’re
            having. Find another{' '}
            <a
              className="text-teal-900"
              href="https://www.youtube.com/user/gwatsup/videos"
              target="_blank"
              rel="noreferrer"
            >
              140+ videos here.
            </a>
          </p>
        </div>
      </div>
      <div className="max-w-6.75xl m-auto">
        <div className="mx-5 sm:mx-8 xl:mx-24">
          <div className=" md:hidden">
            <Swipeable cardWidth={15.625} spaceBetween={1.25}>
              {youtubeArticles.map((item, index) => (
                <YoutubeCardMobile
                  key={index}
                  id={item.id}
                  title={item.title}
                />
              ))}
            </Swipeable>
          </div>
          <div className="hidden grid-cols-rep-3-mx gap-x-4 md:grid">
            {youtubeArticles.map((item, index) => (
              <YoutubeCard key={index} id={item.id} title={item.title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Youtube;
