type Props = {
  id: string;
  title: string;
};

const YoutubeCard = ({ id, title }: Props) => {
  return (
    <div>
      <iframe
        src={`https://www.youtube.com/embed/${id}?controls=0`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder={0}
        className="w-full h-12.5 overflow-hidden rounded-md"
      ></iframe>
      <h3 className="text-xl mt-2.5 mb-1.25 leading-6 font-semibold text-dark-800">
        {title}
      </h3>
    </div>
  );
};
export default YoutubeCard;
