import { gql } from '@apollo/client';

export const PLATFORM_QUERY = gql`
  query PlatformQuery {
    config {
      reviews {
        count
        rating
        consumerAffairs: platform(name: "consumer-affairs") {
          count
          rating
        }
        reviewsIO: platform(name: "reviews.io") {
          count
          rating
        }
        trustpilot: platform(name: "trustpilot") {
          count
          rating
        }
        google: platform(name: "google") {
            count
            rating
        }
      }
    }
  }
`;
