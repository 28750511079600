import { generateUrl } from '@/Components/g-image';
import { useTransformationBasedOnWindowSize } from '@/Lib/hooks/useTransformationBasedOnWindowSize';
import { useEffect, useState } from 'react';
import Head from 'next/head';

type Props = {
  children: JSX.Element;
  image: string;
  main?: boolean;
  hasMoreHeightInDesktop?: boolean;
};
const Banner = ({
  children,
  image,
  main,
  hasMoreHeightInDesktop = false,
}: Props) => {
  const transformation = useTransformationBasedOnWindowSize();
  const [url, setUrl] = useState<string>('');

  // the reason for using the url like this is to force re-render
  // since the server sets a mobile screen transformation by default
  useEffect(() => {
    setUrl(generateUrl(image, undefined, undefined, transformation));
  }, [image, transformation]);

  return (
    <>
      {/* we want to preload (high priority fetch) only if the image is above the fold */}
      {main ? (
        <Head>
          <link rel="preload" href={url} as="image" />
        </Head>
      ) : null}
      {main ? (
        <div
          className={
            'w-full h-auto relative -mb-12 md:mb-0' +
            (hasMoreHeightInDesktop ? ' md:h-500' : ' md:h-100')
          }
        >
          <div
            className={
              'w-full relative h-18.75  bg-50 bg-no-repeat bg-cover md:absolute' +
              (hasMoreHeightInDesktop ? ' md:h-500' : ' md:h-100')
            }
            style={{
              backgroundImage: `url(${url})`,
            }}
          ></div>
          <div className="md:absolute md:top-0 md:left-0 md:bottom-0 md:bg-banner-md md:right-36 lg:bg-banner-lg lg:right-100"></div>
          <div className="max-w-6.75xl m-auto h-auto relative bg-light-600 -top-14 my-0 mx-5 shadow-banner md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:bg-transparent md:h-full md:shadow-none md:mx-auto">
            <div className="h-full mx-8 flex items-center xl:mx-24">
              <div className="my-8 md:max-w-31.25rem lg:py-8">{children}</div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              'w-full relative h-auto  bg-50 bg-no-repeat bg-cover ' +
              (main ? 'h-18.75 md:absolute md:h-100' : 'md:h-34.375')
            }
            style={{
              backgroundImage: `url(${url})`,
            }}
          >
            {' '}
            <div className="md:absolute md:top-0 md:left-0 md:bottom-0 md:bg-banner-md md:right-36 lg:bg-banner-lg lg:right-100"></div>
            <div className="max-w-6.75xl m-auto h-full md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:bg-transparent">
              <div className="h-full mx-8 flex items-center xl:mx-24">
                <div className="my-8 p-8 md:max-w-31.25rem mad:p-0 bg-bannerback md:bg-transparent">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Banner;
