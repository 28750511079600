import Banner from '@/Components/banner';
import VendorReview from '@/Components/reviews/vendor-review';
import Youtube from '@/Components/reviews/youtube';
import GalleryReview from '@/Components/reviews/gallery-review';
import VendorList from '@/Components/vendor-list';
import { FC, useEffect } from 'react';
import { GetStaticProps } from 'next';
import { initializeApollo } from '@/Lib/graphql/apollo-client';
import { useQuery } from '@apollo/client';
import { CountData } from '@/Lib/types/count-data';
import { ConfigQuery } from '@/Lib/graphql/queries/config.query';
import { toCount } from '@/Lib/helpers/round-number';
import { navigationDataFn } from '@/Lib/function/navigation';
import { SOCIAL_MEDIA_POSTS_QUERY } from '@/Lib/graphql/queries/gallery.query';
import { galleryData } from '@/Lib/types/gallery';
import { getMeta } from '@/Components/meta/meta.query';
import { getHeaderData } from '@/Lib/graphql/helpers/header-data';
import { PLATFORM_QUERY } from '@/Lib/graphql/queries/platform.query';
import { getOfficeData } from '@/Lib/graphql/helpers/office-data';
import { useSetAtom } from 'jotai';
import { pageDetail } from '@/State/global/global.store';

type props = {
  galleryData: galleryData;
};

const Reviews: FC<props> = ({ galleryData }) => {
  const { data } = useQuery<{ config: CountData }>(ConfigQuery);
  const tripCount = toCount(data ? data.config.tripCount : 0)?.toLocaleString();
  const setPageDetail = useSetAtom(pageDetail);
  useEffect(() => {
    setPageDetail({ name: 'reviews' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Banner main={true} image="website/static/reviews/banner.jpg">
        <>
          <h1 className="text-5.5xl leading-10 mb-4 text-dark-800 md:text-light-600 font-semibold">
            Reviews
          </h1>
          <p className="text-lg leading-6 text-dark-700 md:text-light-600">
            We’ve helped over {tripCount} travellers and counting make their
            travel dreams come true. It’s no wonder our Global Travellers have
            had great things to share from their experiences around the world.
          </p>
        </>
      </Banner>
      <div className="max-w-6.75xl m-auto">
        <VendorReview />
        <GalleryReview galleryData={galleryData} />
      </div>
      <Youtube />
      <VendorList />
    </div>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo();

  const meta = await getMeta(apolloClient, '/reviews');

  await apolloClient.query<{ config: CountData }>({ query: ConfigQuery });
  await apolloClient.query<{ config: CountData }>({ query: PLATFORM_QUERY });

  const navigationData = await navigationDataFn(apolloClient);
  const headerData = await getHeaderData(apolloClient);
  const offices = await getOfficeData(apolloClient);

  const { data: galleryData } = await apolloClient.query<any>({
    query: SOCIAL_MEDIA_POSTS_QUERY,
    variables: {
      tags: ['Generic'],
      min: 14,
    },
  });

  return {
    props: {
      meta,
      navigationData,
      headerData,
      offices,
      galleryData,
    },
    revalidate: 3600,
  };
};

export default Reviews;
